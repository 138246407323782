@font-face {
  font-family: 'Futura Lt BT';
  src: url(./assets/futura-lt-bt.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Futura Hv BT';
  src: url(./assets/futura-hv-bt-heavy.ttf) format('truetype');
  font-display: block;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(235deg) brightness(102%) contrast(100%);
}

.fullHeightNoHeader {
  height: calc(100vh - 72px);
}

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}

.row .three{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line{
  width: 20px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-height {
  height: calc(41.6667vw);
}

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.carousel-container ul li {
  padding: 0 1.5em;
}

.carousel-container .react-multi-carousel-item--active {
  opacity: 1!important;

}

.carousel-container .react-multi-carousel-item {
  opacity: 0.25;
  /* -webkit-transition: opacity .75s ease-in-out;
  -moz-transition: opactiy .75s ease-in-out;
  -ms-transition: opacity .75s ease-in-out;
  -o-transition: opacity .75s ease-in-out;
  transition: opacity .75s ease-in-out; */
}

html::-webkit-scrollbar {
  width: 0px;
  background: #000b0f;
  z-index: 999999999999999;
}
​
html::-webkit-scrollbar-thumb {
  border: 0px solid #7090e9;
  border-radius: .3em;
}

.modal::-webkit-scrollbar {
  width: 0px;
  background: #000b0f;
  z-index: 0;
}
​
.modal::-webkit-scrollbar-thumb {
  border: 0px solid #7090e9;
  border-radius: .3em;
}


.iframe-container {
  overflow: hidden;
  padding-top: 42%;
  position: relative;
}


.iframe-container-special-p {
  padding-top: 42.8%;
  overflow: hidden;
  position: relative;
}

.iframe-container iframe,
.iframe-container-special-p iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.iframe-container-mo {
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.iframe-container-mo iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.iframe-gradient:hover {
  display: none;
}


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.slider {
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0), #000, rgba(0,0,0,0));
  mask-image: linear-gradient(to right, rgba(0,0,0,0), #000, rgba(0,0,0,0));
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: auto;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
/*
.slide {
  height: 50px;
  width: 250px;
} */

/* .about-team-item {
  margin: 0 auto;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, #000b0f 100%);
  mask-image: -webkit-radial-gradient(circle, white 100%, #000b0f 100%);
} */


.video-about-item {
  /* border: 3px solid #083a59; */
  border: 2px solid #fff;
}